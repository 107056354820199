import { createSelector, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import { GlobalState } from '../store';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { CloudActions } from '../actions/cloud.actions';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { GCSObject, GCSObjectType } from '../../models/gcs-object';

export interface CloudState {
    gcsObjects: EntityState<GCSObject>;
    currentOpenTextFile: any;
}

export const initialCloudState: CloudState = {
    gcsObjects: { ids: [], entities: {} },
    currentOpenTextFile: {}
};

const selectId = (entity: any) => entity.id;
const gcsObjectsAdapter: EntityAdapter<GCSObject> = createEntityAdapter<GCSObject>({
    selectId,
    sortComparer: (a: GCSObject, b: GCSObject) => (a.timeCreated > b.timeCreated ? -1 : 1)
});

export function cloudReducer(
    state: CloudState = initialCloudState,
    action: PayloadAction
): CloudState {
    switch (action.type) {
        case CloudActions.OBJECTS_GCS_GET_SUCCESS:
            return {
                ...state,
                gcsObjects: gcsObjectsAdapter.setAll(action.payload, state.gcsObjects)
            };
        case CloudActions.OBJECTS_GCS_NEW_SUCCESS:
            return {
                ...state,
                gcsObjects: gcsObjectsAdapter.addOne(action.payload, state.gcsObjects)
            };
        case CloudActions.OBJECTS_GCS_UPDATE_SUCCESS:
            return {
                ...state,
                gcsObjects: gcsObjectsAdapter.updateOne(
                    { id: action.payload.id, changes: action.payload },
                    state.gcsObjects
                )
            };
        case CloudActions.OBJECTS_GCS_DELETE_SUCCESS:
            const idKeys = action.payload.map(f => f.id);
            const nameKeys = action.payload.map(f => f.name);
            return {
                ...state,
                gcsObjects: gcsObjectsAdapter.removeMany(obj => {
                    if (obj.type === GCSObjectType.FOLDER) {
                        return nameKeys.includes(obj.id);
                    } else {
                        return idKeys.includes(obj.id);
                    }
                }, state.gcsObjects)
            };
        case CloudActions.OBJECTS_GCS_FILE_GET_SUCCESS:
            return {
                ...state,
                currentOpenTextFile: action.payload
            };
        case CloudActions.OBJECTS_GCS_FILE_RESET:
            return {
                ...state,
                currentOpenTextFile: initialCloudState.currentOpenTextFile
            };
        default:
            return state;
    }
}

const getGCSObjects = createFeatureSelector<MemoizedSelector<GlobalState, any>>(
    'gcsObjects'
).projector((state: GlobalState) => state.cloud.gcsObjects);
const getCurrentOpenTextFile = (state: GlobalState) => state.cloud.currentOpenTextFile;

const defaultGCSObjectsSelectors = gcsObjectsAdapter.getSelectors();

export const gcsObjectsSelector = createSelector(
    getGCSObjects,
    defaultGCSObjectsSelectors.selectAll
);
export const currentOpenTextFileSelector = createSelector(
    [getCurrentOpenTextFile],
    currentOpenTextFile => currentOpenTextFile
);
