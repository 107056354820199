export enum GCSObjectType {
    FILE = 'file',
    FOLDER = 'folder'
}

export enum GCSObjectContentType {
    FOLDER = 'application/x-www-form-urlencoded'
}

export enum YouTubeVideoProcessingStatus {
    FAILED = 'failed',
    PROCESSING = 'processing',
    SUCCEEDED = 'succeeded',
    TERMINATED = 'terminated',
    UPLOADING = 'uploading', // GameLeap custom prop - doesn't exist in YouTube API
    PREPARING = 'preparing' // GameLeap custom prop - doesn't exist in YouTube API
}

interface YouTubeVideoThumbnail {
    url: string;
    width: number;
    height: number;
}

interface YouTubeVideo {
    kind: string;
    etag: string;
    id: string;
    snippet: {
        publishedAt: Date;
        channelId: string;
        title: string;
        description: string;
        thumbnails: {
            default: YouTubeVideoThumbnail;
            medium: YouTubeVideoThumbnail;
            high: YouTubeVideoThumbnail;
        };
        channelTitle: string;
        categoryId: string;
        liveBroadcastContent: string;
        localized: {
            title: string;
            description: string;
        };
    };
    status: {
        uploadStatus: string;
        privacyStatus: string;
        license: string;
        embeddable: boolean;
        publicStatsViewable: boolean;
    };
}

interface YouTubeVideoProcessing {
    processingStatus: YouTubeVideoProcessingStatus;
    processingDetails: {
        processingStatus: string | null;
        processingProgress: { partsTotal: number; partsProcessed: number; timeLeftMs: number };
        processingFailureReason: string | null;
        fileDetailsAvailability: string | null;
        processingIssuesAvailability: string | null;
        tagSuggestionsAvailability: string | null;
        editorSuggestionsAvailability: string | null;
        thumbnailsAvailability: string | null;
    };
    fileDetailsAvailability: string;
    processingIssuesAvailability: string;
    tagSuggestionsAvailability: string;
    editorSuggestionsAvailability: string;
    thumbnailsAvailability: string;
}

export interface GCSObject {
    id: string;
    name: string;
    newName?: string;
    path: string;
    contentType: string;
    signedUrl: string;
    size: number;
    timeCreated: Date;
    type: GCSObjectType;
    metadata: {
        youtubeVideo: YouTubeVideo;
        youtubeVideoProcessing: YouTubeVideoProcessing;
    } | null;
}
