
export interface SocketEndpoints {
    joinRoom: string;
    leaveRoom: string;
    newComment: string;
    updateComment: string;
    newNotification: string;
    statsRefresh: string;
    updateDrawing: string;
}

export const SocketEndpoints: SocketEndpoints = {
    joinRoom: 'rooms.join',
    leaveRoom: 'rooms.leave',
    newComment: 'comments.newComment',
    updateComment: 'comments.updateComment',
    newNotification: 'notifications.newNotification',
    statsRefresh: 'stats.refresh',
    updateDrawing: 'referrals.updateDrawing'
};

export interface SocketErrorEndpoints {
    statsRefreshError: string;
}

export const SocketErrorEndpoints: SocketErrorEndpoints = {
    statsRefreshError: 'stats.refresh.error'
};

export interface ISocketRooms {
    comments: {
        overwatch: string;
        overwatch2: string;
        league: string;
        fortnite: string;
        valorant: string;
        dota: string;
    };
    notifications: {
        overwatch: string;
        overwatch2: string;
        league: string;
        fortnite: string;
        valorant: string;
        dota: string;
    };
    user: string;
    affiliate: string;
    battleTag: string;
}

export const SocketRooms: ISocketRooms = {
    comments: {
        overwatch: 'rooms.comments.overwatch_',
        overwatch2: 'rooms.comments.overwatch2_',
        dota: 'rooms.comments.dota_',
        fortnite: 'rooms.comments.fortnite_',
        valorant: 'rooms.comments.valorant_',
        league: 'rooms.comments.league_'
    },
    notifications: {
        overwatch: 'rooms.notifications.overwatch_',
        overwatch2: 'rooms.notifications.overwatch2_',
        dota: 'rooms.notifications.dota_',
        fortnite: 'rooms.notifications.fortnite_',
        valorant: 'rooms.notifications.valorant_',
        league: 'rooms.notifications.league_'
    },
    user: 'rooms.user_',
    affiliate: 'rooms.affiliate_',
    battleTag: 'rooms.btag_'
};

