import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, mergeMap, takeUntil, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiEndpoints } from '../../core/constants/ApiEndpoints';
import { MainHttp } from '../../core/services/main-http.service';
import { GlobalErrorTypes } from '../../core/constants/error.types';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DownloadItem } from '../../shared/modules/files-manager/download-item/download-item.class';
import { CloudActions } from '../actions/cloud.actions';
import { ICloudEffects } from '../interfaces/cloud-types.interfaces';
import { GCSObject } from '../../models/gcs-object';

@Injectable()
export class CloudEffects extends ICloudEffects {
    constructor(
        private actions$: Actions,
        private http: MainHttp,
        private cloudActions: CloudActions,
        private httpClient: HttpClient
    ) {
        super();
    }

    getGCSObjectsSignedURL = createEffect(() => this.actions$.pipe(
        ofType<PayloadAction>(CloudActions.OBJECTS_GCS_SIGNED_URL_GET),
        mergeMap(({ payload }) => {
            const url = ApiEndpoints.getGCSObjectsSignedURL(payload.affiliateId);

            return this.http.post(url, payload.data).pipe(
                map(data => this.cloudActions.objectsGCSGetSignedURLSuccess(data)),
                catchError(error =>
                    of(
                        this.cloudActions.cloudErrorNew({
                            type: GlobalErrorTypes.getGCSObjectsSignedURL,
                            error: error
                        })
                    )
                )
            );
        })
    ));

    getGCSObjects = createEffect(() => this.actions$.pipe(
        ofType<PayloadAction>(CloudActions.OBJECTS_GCS_GET),
        mergeMap(({ payload }) => {
            const url = ApiEndpoints.getGCSObjects(payload.affiliateId);

            return this.http.get(url, payload).pipe(
                map(data => this.cloudActions.objectsGCSGetSuccess(data)),
                catchError(error =>
                    of(
                        this.cloudActions.cloudErrorNew({
                            type: GlobalErrorTypes.getGCSObjects,
                            error: error
                        })
                    )
                )
            );
        })
    ));

    moveGCSObjects = createEffect(() => this.actions$.pipe(
        ofType<PayloadAction>(CloudActions.OBJECTS_GCS_MOVE),
        mergeMap(({ payload }) => {
            const url = ApiEndpoints.moveGCSObjects(payload.affiliateId);
            // The commented out code is used to instantly update the client object
            // const gcsObjects = payload.data.gcsObjects;
            // const action =
            //     payload.eventType === 'delete'
            //         ? this.cloudActions.objectsGCSDeleteSuccess(gcsObjects)
            //         : this.cloudActions.objectsGCSUpdateSuccess({
            //               ...gcsObjects[0],
            //               name: payload.data.files[0].destinationKey
            //           });

            return this.http.post(url, { files: payload.data.files }).pipe(
                // dispatch delete action because right now move === delete operation
                map(data => this.cloudActions.objectsGCSMoveSuccess(data)),
                catchError(error =>
                    of(
                        this.cloudActions.cloudErrorNew({
                            type: GlobalErrorTypes.moveGCSObjects,
                            error: error
                        })
                    )
                )
            );
        })
    ));

    copyGCSObjects = createEffect(() => this.actions$.pipe(
        ofType<PayloadAction>(CloudActions.OBJECTS_GCS_COPY),
        mergeMap(({ payload }) => {
            const url = ApiEndpoints.copyGCSObjects(payload.affiliateId);
            // The commented out code is used to instantly update the client object
            // const gcsObjects = payload.data.gcsObjects;
            // const action =
            //     payload.eventType === 'delete'
            //         ? this.cloudActions.objectsGCSDeleteSuccess(gcsObjects)
            //         : this.cloudActions.objectsGCSUpdateSuccess({
            //               ...gcsObjects[0],
            //               name: payload.data.files[0].destinationKey
            //           });

            return this.http.post(url, { files: payload.data.files }).pipe(
                // dispatch delete action because right now move === delete operation
                map(data => this.cloudActions.objectsGCSCopySuccess(data)),
                catchError(error =>
                    of(
                        this.cloudActions.cloudErrorNew({
                            type: GlobalErrorTypes.copyGCSObjects,
                            error: error
                        })
                    )
                )
            );
        })
    ));

    deleteGCSObjects = createEffect(() => this.actions$.pipe(
        ofType<PayloadAction>(CloudActions.OBJECTS_GCS_DELETE),
        mergeMap(({ payload }) => {
            const url = ApiEndpoints.deleteGCSObjects(payload.affiliateId);

            return this.http.delete(url, payload.data).pipe(
                map(data => this.cloudActions.objectsGCSDeleteSuccess(data)),
                catchError(error =>
                    of(
                        this.cloudActions.cloudErrorNew({
                            type: GlobalErrorTypes.deleteGCSObjects,
                            error: error
                        })
                    )
                )
            );
        })
    ));

    downloadGCSObjects = createEffect(() => this.actions$.pipe(
        ofType<PayloadAction>(CloudActions.OBJECTS_GCS_DOWNLOAD),
        mergeMap(({ payload }) => {
            const item: DownloadItem = payload.data.item;
            const url = ApiEndpoints.downloadGCSObjects(payload.affiliateId);
            delete payload.data.item;

            return this.http.get(url, payload.data).pipe(
                map(data =>
                    this.cloudActions.objectsGCSDownloadSuccess({
                        ...data,
                        item
                    })
                ),
                catchError(error =>
                    of(
                        this.cloudActions.cloudErrorNew({
                            type: GlobalErrorTypes.downloadGCSObjects,
                            error: error
                        })
                    )
                ),
                takeUntil(item.isCanceled$.pipe(filter(val => val)))
            );
        })
    ));

    getGCSFile = createEffect(() => this.actions$.pipe(
        ofType<PayloadAction>(CloudActions.OBJECTS_GCS_FILE_GET),
        mergeMap(({ payload }) => {
            const item: GCSObject = payload;

            return this.httpClient.get(item.signedUrl, { responseType: 'text' }).pipe(
                map(data => {
                    const file = {
                        id: item.id,
                        name: item.name,
                        content: data
                    };
                    return this.cloudActions.objectsGCSFileGetSuccess(file);
                }),
                catchError(error =>
                    of(
                        this.cloudActions.cloudErrorNew({
                            type: GlobalErrorTypes.getGCSFile,
                            error: error
                        })
                    )
                )
            );
        })
    ));

    uploadGCSObjectToYoutube = createEffect(() => this.actions$.pipe(
        ofType<PayloadAction>(CloudActions.OBJECTS_GCS_UPLOAD_TO_YOUTUBE),
        mergeMap(({ payload }) => {
            const url = ApiEndpoints.uploadGCSObjectToYoutube;

            return this.http.post(url, payload).pipe(
                catchError(error =>
                    of(
                        this.cloudActions.cloudErrorNew({
                            type: GlobalErrorTypes.uploadGCSObjectToYoutube,
                            error: error
                        })
                    )
                )
            );
        })
    ));
}
