import { GCSObject } from '../../models/gcs-object';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class ICloudActions {
    abstract cloudErrorNew(error: any);
    abstract cloudErrorReset(error: any);
    abstract objectsGCSGetSignedURL(signData);
    abstract objectsGCSGetSignedURLSuccess(data);
    abstract objectsGCSGet(query);
    abstract objectsGCSGetSuccess(objects: GCSObject[]);
    abstract objectsGCSNewSuccess(object: GCSObject);
    abstract objectsGCSUpdateSuccess(object: GCSObject);
    abstract objectsGCSDelete(deleteData);
    abstract objectsGCSDeleteSuccess(object: GCSObject[]);
    abstract objectsGCSMove(moveData);
    abstract objectsGCSMoveSuccess(payload);
    abstract objectsGCSCopy(copyData);
    abstract objectsGCSCopySuccess(payload);
    abstract objectsGCSDownload(downloadData);
    abstract objectsGCSDownloadSuccess(data: { signedUrl: string });
    abstract objectsGCSFileGet(item: GCSObject);
    abstract objectsGCSFileGetSuccess(payload: any);
    abstract objectsGCSFileReset();
    abstract objectsGCSUploadToYoutube(payload: {
        userId: string;
        youtubeAccId: string;
        key: string;
        name: string;
        size: number;
        contentType: string;
    });
}

@Injectable()
export abstract class ICloudEffects {
    abstract getGCSObjectsSignedURL;
    abstract getGCSObjects;
    abstract moveGCSObjects;
    abstract deleteGCSObjects;
    abstract downloadGCSObjects;
    abstract getGCSFile;
}

@Injectable()
export abstract class ICloudSelectors {
    abstract error$;
    abstract gcsObjects$;
    abstract currentOpenTextFile$;
}
