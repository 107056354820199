import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GCSActionType } from '../../core/constants/gcs-action-types.enum';
import { UploadType } from '../../core/constants/upload-types.enum';
import { GCSObject } from '../../models/gcs-object';
import { DownloadItem } from '../../shared/modules/files-manager/download-item/download-item.class';
import { ICloudActions } from '../interfaces/cloud-types.interfaces';
import { GlobalState } from '../store';

@Injectable()
export class CloudActions extends ICloudActions {
    static CLOUD_ERROR_NEW = 'CLOUD_ERROR_NEW';
    static CLOUD_ERROR_RESET = 'CLOUD_ERROR_RESET';
    static OBJECTS_GCS_GET = 'OBJECTS_GCS_GET';
    static OBJECTS_GCS_GET_SUCCESS = 'OBJECTS_GCS_GET_SUCCESS';
    static OBJECTS_GCS_SIGNED_URL_GET = 'OBJECTS_GCS_SIGNED_URL_GET';
    static OBJECTS_GCS_SIGNED_URL_GET_SUCCESS = 'OBJECTS_GCS_SIGNED_URL_GET_SUCCESS';
    static OBJECTS_GCS_NEW_SUCCESS = 'OBJECTS_GCS_NEW_SUCCESS';
    static OBJECTS_GCS_UPDATE_SUCCESS = 'OBJECTS_GCS_UPDATE_SUCCESS';
    static OBJECTS_GCS_MOVE = 'OBJECTS_GCS_MOVE';
    static OBJECTS_GCS_MOVE_SUCCESS = 'OBJECTS_GCS_MOVE_SUCCESS';
    static OBJECTS_GCS_COPY = 'OBJECTS_GCS_COPY';
    static OBJECTS_GCS_COPY_SUCCESS = 'OBJECTS_GCS_COPY_SUCCESS';
    static OBJECTS_GCS_DELETE = 'OBJECTS_GCS_DELETE';
    static OBJECTS_GCS_DELETE_SUCCESS = 'OBJECTS_GCS_DELETE_SUCCESS';
    static OBJECTS_GCS_DOWNLOAD = 'OBJECTS_GCS_DOWNLOAD';
    static OBJECTS_GCS_DOWNLOAD_SUCCESS = 'OBJECTS_GCS_DOWNLOAD_SUCCESS';
    static OBJECTS_GCS_FILE_GET = 'OBJECTS_GCS_FILE_GET';
    static OBJECTS_GCS_FILE_GET_SUCCESS = 'OBJECTS_GCS_FILE_GET_SUCCESS';
    static OBJECTS_GCS_FILE_RESET = 'OBJECTS_GCS_FILE_RESET';
    static OBJECTS_GCS_UPLOAD_TO_YOUTUBE = 'OBJECTS_GCS_UPLOAD_TO_YOUTUBE';

    constructor(private store: Store<GlobalState>) {
        super();
    }

    cloudErrorNew(error: any) {
        return {
            type: CloudActions.CLOUD_ERROR_NEW,
            payload: error
        };
    }

    cloudErrorReset(error: any) {
        return this.store.dispatch({
            type: CloudActions.CLOUD_ERROR_RESET,
            payload: error
        });
    }

    objectsGCSGetSignedURL(signData: {
        affiliateId: string;
        data: {
            type: UploadType;
            key: string;
            mimeType: string;
            action: GCSActionType;
            fileName: string;
            expiration?: number;
            responseDisposition?: string;
        };
    }) {
        return this.store.dispatch({
            type: CloudActions.OBJECTS_GCS_SIGNED_URL_GET,
            payload: signData
        });
    }

    objectsGCSGetSignedURLSuccess(data: { signedUrl: string; key: string; type: UploadType }) {
        return {
            type: CloudActions.OBJECTS_GCS_SIGNED_URL_GET_SUCCESS,
            payload: data
        };
    }

    objectsGCSGet(query: {
        affiliateId: string;
        prefix: string;
        delimiter: string;
        includeTrailingDelimiter: boolean;
    }) {
        return this.store.dispatch({
            type: CloudActions.OBJECTS_GCS_GET,
            payload: query
        });
    }

    objectsGCSGetSuccess(objects: GCSObject[]) {
        return {
            type: CloudActions.OBJECTS_GCS_GET_SUCCESS,
            payload: objects
        };
    }

    objectsGCSNewSuccess(object: GCSObject) {
        return this.store.dispatch({
            type: CloudActions.OBJECTS_GCS_NEW_SUCCESS,
            payload: object
        });
    }

    objectsGCSUpdateSuccess(object: GCSObject) {
        return {
            type: CloudActions.OBJECTS_GCS_UPDATE_SUCCESS,
            payload: object
        };
    }

    objectsGCSDelete(deleteData: { affiliateId: string; data: { key: string } }) {
        return this.store.dispatch({
            type: CloudActions.OBJECTS_GCS_DELETE,
            payload: deleteData
        });
    }

    objectsGCSDeleteSuccess(objects: GCSObject[]) {
        return {
            type: CloudActions.OBJECTS_GCS_DELETE_SUCCESS,
            payload: objects
        };
    }

    objectsGCSMove(moveData: {
        affiliateId: string;
        eventType?: string;
        data: { files: { key: string; destinationKey: string }[]; gcsObjects: GCSObject[] };
    }) {
        return this.store.dispatch({
            type: CloudActions.OBJECTS_GCS_MOVE,
            payload: moveData
        });
    }

    objectsGCSMoveSuccess(payload) {
        return {
            type: CloudActions.OBJECTS_GCS_MOVE_SUCCESS,
            payload
        };
    }

    objectsGCSCopy(copyData: {
        affiliateId: string;
        data: { files: { key: string; destinationKey: string }[]; gcsObjects: GCSObject[] };
    }) {
        return this.store.dispatch({
            type: CloudActions.OBJECTS_GCS_COPY,
            payload: copyData
        });
    }

    objectsGCSCopySuccess(payload) {
        return {
            type: CloudActions.OBJECTS_GCS_COPY_SUCCESS,
            payload
        };
    }

    objectsGCSDownload(downloadData: {
        affiliateId: string;
        data: {
            prefix: string;
            delimiter: string;
            includeTrailingDelimiter: boolean;
            downloadName: string;
            item: DownloadItem;
        };
    }) {
        return this.store.dispatch({
            type: CloudActions.OBJECTS_GCS_DOWNLOAD,
            payload: downloadData
        });
    }

    objectsGCSDownloadSuccess(data: { signedUrl: string }) {
        return {
            type: CloudActions.OBJECTS_GCS_DOWNLOAD_SUCCESS,
            payload: data
        };
    }

    objectsGCSFileGet(item: GCSObject) {
        return this.store.dispatch({
            type: CloudActions.OBJECTS_GCS_FILE_GET,
            payload: item
        });
    }

    objectsGCSFileGetSuccess(payload: any) {
        return {
            type: CloudActions.OBJECTS_GCS_FILE_GET_SUCCESS,
            payload
        };
    }

    objectsGCSFileReset() {
        return this.store.dispatch({
            type: CloudActions.OBJECTS_GCS_FILE_RESET
        });
    }

    objectsGCSUploadToYoutube(payload: {
        userId: string;
        youtubeAccId: string;
        key: string;
        name: string;
        size: number;
        contentType: string;
    }) {
        return this.store.dispatch({
            type: CloudActions.OBJECTS_GCS_UPLOAD_TO_YOUTUBE,
            payload
        });
    }
}
