import { UserSubscription } from './user-subscription.model';
import { PaymentMethod, PaymentSource } from './payment-method.model';

export interface UserPayment {
    _id: string;
    amount: number;
    subscription: UserSubscription;
    customer: any;
    userId: string;
    planId: string;
    plan: string;
    planName: string;
    source: PaymentSource;
    sourceId: string;
    isRefunded: boolean;
    date: Date;
    periodStart: Date;
    periodEnd: Date;
    isRecurring: boolean;
    paymentMethod: PaymentMethod;
    currency: string;
}

export enum PaymentType {
    RECURRING = 'recurring',
    NON_RECURRING = 'nonRecurring'
}

export const PaymentTypes = Object.values(PaymentType);