export enum TimeUnit {
    HOUR = 'hour',
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month'
}

export enum EventKind {
    VISIT = 'visit',
    SIGNUP = 'signup',
    UPGRADE = 'upgrade',
    PRODUCT_SUBSCRIBE = 'product_subscribe'
}

export interface AffiliateEvent {
    _id: string;
    affiliate: string;
    campaign: string | null;
    kind: EventKind;
    createdAt: Date;
    updatedAt: Date;
}

export const EventKinds = Object.values(EventKind);