import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { UserSelectors } from '../../store/selectors/user.selectors';
import { Account } from '../../models/account.model';
import { UserRoles } from '../../models/user-role-map.model';

@Injectable()
export class CanActivatePartner  {
    private isLoggedIn: boolean;
    private userRoles: UserRoles;

    constructor(private userSelectors: UserSelectors, private router: Router) {
        this.userSelectors.isLoggedIn$.subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        });
        this.userSelectors.userRoles$.subscribe(userRoles => {
            this.userRoles = userRoles;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.isLoggedIn) {
            this.router.navigate(['/login'], {
                queryParams: { returnTo: state.url },
                replaceUrl: true
            });
            return false;
        } else if (
            this.isLoggedIn &&
            (this.userRoles.isAdmin ||
                this.userRoles.isManager ||
                (this.userRoles.isPlayer || this.userRoles.isInfluencer))
        ) {
            return true;
        }
        return false;
    }
}

@Injectable()
export class CanActivatePartnerEarnings  {
    private account: Account;
    private userRoles: UserRoles;

    constructor(private userSelectors: UserSelectors, private router: Router) {
        this.userSelectors.account$.subscribe(account => {
            this.account = account;
        });
        this.userSelectors.userRoles$.subscribe(userRoles => {
            this.userRoles = userRoles;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (
            (route.params.affiliateId === this.account.affiliateId &&
                this.userRoles.isInfluencer) ||
            this.userRoles.isAdmin
        ) {
            return true;
        }

        return false;
    }
}
