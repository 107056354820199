import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalState } from '../store';
import { GCSObject } from '../../models/gcs-object';
import { gcsObjectsSelector, currentOpenTextFileSelector } from '../reducers/cloud.reducer';
import { ICloudSelectors } from '../interfaces/cloud-types.interfaces';

@Injectable()
export class CloudSelectors extends ICloudSelectors {
    public error$: Observable<any>;
    public gcsObjects$: Observable<GCSObject[]>;
    public currentOpenTextFile$: Observable<any>;

    constructor(private store: Store<GlobalState>) {
        super();
        this.gcsObjects$ = store.pipe(select(gcsObjectsSelector));
        this.currentOpenTextFile$ = store.pipe(select(currentOpenTextFileSelector));
    }
}
