import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { io } from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { SocketEndpoints } from '../constants/SocketSettings';

@Injectable()
export class SocketService {
    private rooms: Set<string> = new Set();
    private token: string | null;
    private reconnectionInterval;
    private reconnectionAttempts = 0;
    private eventsSubject: { [event: string]: Subject<any> } = {};
    private eventHandlers: { [event: string]: (data: any) => void } = {};

    public socket: any;
    public isConnected: boolean = false;
    public isReconnecting: boolean = false;
    public wsId: string;

    constructor(private ngZone: NgZone) {}

    set authToken(token) {
        this.token = token;
    }

    /**
     * Established a new socket connection.
     */
    public connect() {
        this.ngZone.runOutsideAngular(() => {
            if (this.isConnected) return;

            if (this.socket && this.isConnected) {
                this.disconnect(true);
            }
            this.socket = null;
            let url = environment.socketRoot;
            if (this.token) {
                url = `${url}?token=${this.token}`;
            }
            this.socket = io(url, {
                transports: ['websocket', 'xhr-polling'],
                withCredentials: true
            });
            this.socket.on('connect', data => {
                console.log('Socket recovered: ', this.socket.recovered);
                this.isConnected = true;
                this.isReconnecting = false;
                this.reconnectionAttempts = 0;
                clearInterval(this.reconnectionInterval);
                if (this.rooms) {
                    this.rooms.forEach(room => this.joinRoom(room));
                }
                for (const event in this.eventHandlers) {
                    this.socket.on(event, this.eventHandlers[event]);
                }
            });
            this.socket.on('message', data => {
                try {
                    data = JSON.parse(data);

                    if (data.typ === 'connection') {
                        this.wsId = data.bdy.id;
                    }
                } catch (err) {
                    console.warn('Error parsing WS message.', err);
                }
            });
            this.socket.on('disconnect', reason => {
                console.log('WS disconnected');
                this.isConnected = false;
                this.isReconnecting = true;
                this.reconnect();
            });
            this.socket.on('unauthorized', function(error) {
                if (
                    error.data.type === 'UnauthorizedError' ||
                    error.data.code === 'invalid_token'
                ) {
                    console.log("CMSUser's token has expired");
                }
            });
        });
    }

    /**
     * Disconnects the current socket connection.
     */
    public disconnect(close: boolean) {
        this.socket.off('connect');
        this.socket.off('disconnect');
        this.socket.off('unauthorized');
        this.socket.off('message');
        this.socket.disconnect(close);
    }

    /**
     * Attempts to reconnect to the socket server.
     */
    reconnect() {
        this.ngZone.runOutsideAngular(() => {
            this.reconnectionInterval = setInterval(() => {
                console.log('Attempting to reconnect... ', this.reconnectionAttempts);
                this.socket.connect();
                this.reconnectionAttempts++;

                if (this.reconnectionAttempts > 10) {
                    console.log('Too many reconnection attempts. Clearing interval.');
                    clearInterval(this.reconnectionInterval);
                } else if (this.socket.connected) {
                    // Re-subscribe to all events
                    for (const event in this.eventHandlers) {
                        this.socket.on(event, this.eventHandlers[event]);
                    }
                }
            }, 2000); // Attempt to reconnect every 2 seconds
        });
    }

    /**
     * Dispatches a socket event to the server.
     * @param event
     * @param data
     */
    public emit(event: string, data?: any) {
        this.socket.emit(event, data);
    }

    /**
     * Binds a request handler to a socket event.
     * @param event
     */
    public on(event: string): Observable<any> {
        if (!this.eventsSubject[event]) {
            this.eventsSubject[event] = new Subject<any>();

            this.eventHandlers[event] = data => {
                this.eventsSubject[event].next(data);
            };

            this.socket.on(event, this.eventHandlers[event]);
        }

        return this.eventsSubject[event].asObservable();
    }

    /**
     * Binds a request handler to a socket event.
     * @param event
     */
    public off(event: string): void {
        if (this.eventsSubject[event]) {
            this.socket.off(event);
            delete this.eventsSubject[event];
        }
    }

    /**
     * Enters the provided room.
     * @param room
     */
    public joinRoom(room: string) {
        if (!this.rooms.has(room)) {
            this.rooms.add(room);
        }

        this.emit(SocketEndpoints.joinRoom, room);
    }

    /**
     * Leaves the provided room.
     * @param room
     */
    public leaveRoom(room: string) {
        if (this.rooms.has(room)) {
            this.rooms.delete(room);
        }

        this.emit(SocketEndpoints.leaveRoom, room);
    }

    /**
     * Removed a socket handler from an event.
     * @param event
     * @param handler
     */
    public removeListener(event: string, handler: Function) {
        this.socket.removeListener(event, handler);
    }
}

